html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.appContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 900px) {
    overflow-y: scroll;
  }
  .background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    top: 0;
    left: 0;
  }
  .logo {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
    top: 5vh;
  }
  .powered {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 5vh;
    z-index: 1;
    color: white;
    text-align: center;
  }
  .loginContainer {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    .title {
      color: white;
      font-size: 42px;
      font-weight: 700;
      letter-spacing: -0.25px;
    }
    input {
      width: 100%;
      max-width: 30rem;
      height: 5rem;
      border: none;
      outline: none;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.3);
      box-shadow: 0px 4px 16px rgba(215, 215, 224, 0.11);
      backdrop-filter: blur(40px);
      color: white;
      font-size: 35px;
      text-align: center;
    }
    input::placeholder {
      color: white;
      font-size: 20px;
    }
    button {
      width: 90%;
      max-width: 17rem;
      height: 3.5rem;
      background: linear-gradient(99.54deg, #aa9eef 22.49%, #9eb5ef 80.41%);
      backdrop-filter: blur(100px);
      border-radius: 10px;
      border: none;
      outline: none;
      color: white;
      margin-top: 2rem;
      cursor: pointer;
    }
  }
}

.panel {
  width: 95%;
  z-index: 1;
  max-width: 90rem;
  max-height: 35rem;
  height: 60vh;
  background: rgba(247, 246, 249, 0.95);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: #3d3f44;
    .title {
      padding: 2rem 4rem;
      font-size: 25px;
      margin: 0;
      font-weight: 700;
      @media screen and (max-width: 900px) {
        text-align: center;
      }
    }
    .filterContainer {
      width: 100%;
      justify-content: center;
      display: flex;
      padding: 0 0 1rem 0;
      .active {
        background: #918fff;
        color: white;
        border: none;
      }
      button {
        width: 10rem;
        height: 2.2rem;
        margin: 0 0.5rem;
        border-radius: 25px;
        border: 0.5px rgb(124, 123, 134) solid;
        cursor: pointer;
        background: transparent;
        color: rgb(72, 71, 80);
        @media screen and (max-width: 900px) {
          margin: 0 0.1rem;
          width: 6rem;
        }
      }
    }
  }
  .pathologyContainer {
    height: auto;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow-y: scroll;
    .pathologies {
      background: rgb(146, 100, 100);
      display: flex;
      justify-content: center;
      padding: 0rem 2rem;
      flex-wrap: wrap;
      height: 0%;
      @media screen and (max-width: 900px) {
        justify-content: flex-start;
      }
    }
    .pathology {
      background: #e7e6eb;
      margin: 0.3rem 0.2rem;
      padding: 0.5rem 2.5rem;
      border-radius: 5px;
      height: 1.5rem;
      color: rgb(58, 52, 80);
      cursor: pointer;
      @media screen and (max-width: 900px) {
        height: auto;
      }
    }
    .activePathology {
      background: rgb(58, 52, 80);
      margin: 0.3rem 0.2rem;
      padding: 0.5rem 2.5rem;
      border-radius: 5px;
      height: 1.5rem;
      color: white;
      cursor: pointer;
      @media screen and (max-width: 900px) {
        height: auto;
      }
    }
  }
  .footer {
    width: 100%;
    height: 5rem;
    display: flex;
    background: #ecedf0;
    border-radius: 0 0 10px 10px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
    .selectedPathology {
      flex: 0 0 24rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.267);
      backdrop-filter: blur(40px);
      margin: 0.5rem;
      border-radius: 5px;
      color: rgb(204, 204, 211);
    }
    .activeSelectedPathology {
      flex: 0 0 24rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgb(255, 255, 255);
      backdrop-filter: blur(40px);
      margin: 0.5rem;
      border-radius: 5px;
      color: rgb(53, 53, 71);
      font-weight: 500;
      box-shadow: 0px 4px 16px rgba(112, 111, 132, 0.11);
      @media screen and (max-width: 900px) {
        flex: 0 0 4rem;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
      }
      p {
        margin: 0;
        margin-bottom: 0.1rem;
      }
    }
    input {
      flex: 1;
      border: none;
      outline: none;
      padding-left: 2rem;
      background: rgb(225, 223, 233);
      margin: 0.5rem;
      border-radius: 5px;
      color: rgb(38, 39, 73);
      font-size: 20px;
      @media screen and (max-width: 900px) {
        padding: 1.5rem 3rem;
        background: white;
        margin: 0;
        border-radius: 5px 5px 0 0;
      }
    }
    input::placeholder {
      color: rgb(109, 110, 136);
    }
    button {
      flex: 0 0 15rem;
      border: none;
      outline: none;
      cursor: pointer;
      background: linear-gradient(99.54deg, #aa9eef 22.49%, #9eb5ef 80.41%);
      color: white;
      margin: 0.5rem;
      border-radius: 5px;
      @media screen and (max-width: 900px) {
        flex: 0 0 4rem;
        margin: 0;
        border-radius: 0 0 5px 5px;
      }
    }
  }
}

.card {
  position: absolute;
  bottom: 2rem;
  z-index: 2;
  width: 24rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
  backdrop-filter: blur(40px);
  margin: 0.5rem;
  border-radius: 10px;
  color: rgb(50, 50, 59);
  font-weight: 500;
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  img {
    width: 20px;
    height: 20px;
    margin-right: 1rem;
    margin-bottom: -0.15rem;
  }
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

/* Animation */

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 10px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
